import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  Container,
  Button,
} from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { RiAddLargeLine } from "react-icons/ri";
import "./StudentDashboard.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {  EmojiEvents } from "@mui/icons-material";
import TableRow from "@mui/material/TableRow";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import "chart.js/auto";
import { UserContext } from "../../context/UserContext";
import { API_URL } from "../../global";
ChartJS.register(ArcElement, Tooltip, Legend, Title);

export default function StudentDashboard() {


  const { id } = useParams();
  const { user } = useContext(UserContext);
  // const role = localStorage.getItem("role");
  // const userId = localStorage.getItem("user_id");

  const navigate = useNavigate();
 // const courseDropdownRef = useRef(null); // Ref to the dropdown
  // const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(false); // State to handle dropdown toggle
//  const [headers, setHeaders] = useState([]); // Dynamically stores headers for sections

  const [getStudentData, setGetStudentData] = useState([]);
  const [activeCourseIndex, setActiveCourseIndex] = useState(0); // Track active course

  const [courseCounts, setCourseCounts] = useState({});
  const [studentCourse, setStudentCourse] = useState([]);
  // const [facultyData, setFacultyData] = useState();

  const columns = [
    { id: "rank", label: "Rank", minWidth: 50 },
    { id: "name", label: "Student Name", minWidth: 100 },
    { id: "score", label: "Student Score", minWidth: 100 },
    { id: "completion", label: "Total Completion (%)", minWidth: 100 },
  ];

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const sortedStudents = [...getStudentData].sort((a, b) => b.score - a.score);

  // // Detect outside click
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       courseDropdownRef.current &&
  //       !courseDropdownRef.current.contains(event.target)
  //     ) {
  //       setIsCourseDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [courseDropdownRef]);

  const fetchStudentGet = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/student/${id}`);
      console.log(response.data.getStudents);
      if (response.status === 200) {
        setGetStudentData(response.data.getStudents);
        calculateCourseCounts(response.data.getStudents);
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  }, [id]);

  const calculateCourseCounts = (students) => {
    const counts = {};
    const allCourses = []; // Aggregate all courses

    students.forEach((student) => {
      student.courses.forEach((course) => {
        counts[course] = (counts[course] || 0) + 1;
        if (!allCourses.includes(course)) {
          // Avoid duplicate courses
          allCourses.push(course);
        }
      });
    });
    setCourseCounts(counts);
    setStudentCourse(allCourses); // Update state with the entire array of courses at once
    console.log(allCourses, "Collected courses...");
  };

  useEffect(() => {
    fetchStudentGet(); // Fetch student data when component mounts
  }, [fetchStudentGet]);

  // useEffect(() => {
  //   const fetchStaffData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `http://localhost:4000/register/${user.user_id}`
  //       );

  //       setFacultyData(response.data.getStaffData._id);
  //      } catch (error) {
  //     console.error("Error fetching sections:", error);
  //      }
  //   };

  //   fetchStaffData();
  // }, []);

  
  const studentPiechart = {
    datasets: [
      {
        data:
          Object.values(courseCounts).length > 0
            ? Object.values(courseCounts)
            : [1], // Set to [1] if no data
        backgroundColor:
          Object.values(courseCounts).length > 0
            ? ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"] // Colors if data exists
            : ["transparent"], // Transparent background if no courses
        borderColor:
          Object.values(courseCounts).length > 0 ? undefined : ["#999"], // Add border outline if no data
        borderWidth: Object.values(courseCounts).length > 0 ? 1 : 2, // Thicker border for empty chart
      },
    ],
  };

  const studentPiechartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      tooltip: {
        enabled: Object.values(courseCounts).length > 0, // Disable tooltip if no data
      },
    },
  };
  

 //  const ranks = ["Rank 1", "Rank 2", "Rank 3"];
 //  const [selectedRank, setSelectedRank] = useState(ranks[0]);
  
//*  behavior: "smooth", */
  // useEffect(() => {
  //   const body = document.querySelector("#root");
  //   body.scrollIntoView(
  //     {
  //       behavior: "smooth",
  //     },
  //     500
  //   );
  //   window.scrollTo(0, 0);
  // }, [headers]);


  // Filter unique courses by `courseId` before mapping
const uniqueCourses = studentCourse.filter(
  (course, index, self) =>
    index === self.findIndex((c) => c.courseId === course.courseId)
);

  // Handle course click to update active course
  const handleCourseClick = (index) => {
    setActiveCourseIndex(index);
  };

  return (
    <>
     
      <br />
      <br />
      <br />

      <Container fluid className="dashboard-container">
         {/* Add-Batch Button aligned right */}
         { user.role === "student" ? 
        null
         : <>
        <div className="d-flex justify-content-end mb-3 mt-3">
          <Button variant="outline-warning" onClick={()=> navigate(`/lms/assign-course/${id}`)}><RiAddLargeLine /> Add_Course</Button>
        </div>
        </>}

        
        {/* Main Content - Image and Doughnut Chart */}
        <div className="dashboard-content d-flex">
          {/* First child - Image */}
          <div className="dashboard-image">
            <img
              src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1728470782/maq28k0m2gkiitvddjc6.png"
              alt="Student-Image"
              className="img-fluid"
            />
          </div>

          {/* Second child - Doughnut Chart */}
          <div className="dashboard-chart">
            <Doughnut data={studentPiechart} options={studentPiechartOptions} />
          </div>
        </div>
      </Container>

        {/* Dynamic headers and modules */}
        <div className="header-container">
          <ul className="header-list"  >
            {uniqueCourses.map((header, index) => (
              <li
                key={index}
                className={`header-item ${activeCourseIndex === index ? "active" : ""}`}
                onClick={() => handleCourseClick(index)}
              >
                <h6>{header.courseName}</h6>
                {activeCourseIndex === index && <div className="active-bar"></div>}
              </li>
            ))}
          </ul>
        </div> 
      {/* {courseHeader} */}


      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper sx={{ width: "95%" }}>
          <TableContainer sx={{  overflow: "hidden" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell className="text-center"
                      key={column.id}
                      style={{
                        minWidth: {
                          xs: column.minWidth * 0.3, // Reduce width on extra-small screens
                          sm: column.minWidth * 0.85, // Slightly wider on small screens
                          md: column.minWidth, // Default width on medium and larger screens
                        },
        
                        color: "#636e16",
                        fontSize: { xs: "12px", sm: "16px", md: "20px" }, 
                        fontWeight:"bold",
                        fontFamily:"Times New Roman"
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            
            
              <TableBody>
                {sortedStudents.length > 0 ? (
                  sortedStudents
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((student, index) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={student._id}
                        onMouseEnter={() => setHoveredRowIndex(index)}
                        onMouseLeave={() => setHoveredRowIndex(null)}
                        style={{
                          height: index === 0 || index === 1 ? "80px" : "48px",
                     
                          backgroundColor:
                            hoveredRowIndex === index
                              ? user.user_id === student._id ? "#5113cf" : "#f6f3b7" 
                              :  user.user_id === student._id
                              ? "#5113cf"
                              : "transparent",
                         
                        }}
                      >
                        <TableCell className="text-center" style={{     color:
                               user.user_id === student._id ? "white" :"black",}}>
                          {index === 0 ? (
                            <EmojiEvents
                              style={{ color: "gold", fontSize: "3rem" }}
                            /> // Rank 1 icon (gold trophy)
                          ) : index === 1 ? (
                            <EmojiEvents
                              style={{ color: "silver", fontSize: "3rem" }}
                            /> 
                          ) : (
                            index + 1 // + page * rowsPerPage 
                            // null
                          )}
                        </TableCell>

                        <TableCell className="text-center" style={{ color: user.user_id === student._id ? "white" : "black" }}>
  {
    user.role === "faculty" || user.role === "admin" || user.user_id === student._id
      ? student.name
      : "**********"
  }
</TableCell>

                        <TableCell className="text-center" style={{     color:
                               user.user_id === student._id ? "white" :"black",}}>
                          {student.score || "Processing ..."}
                        </TableCell>

                        <TableCell className="text-center" style={{     color:
                               user.user_id === student._id ? "white" :"black",}}>
                          {student.completion || "Processing ..."}%
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No Student Data Available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
}