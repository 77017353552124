import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { toast } from "react-toastify";

const ProtectedRoute = () => {
  const { user } = useContext(UserContext);

  if (!user || !user.auth || !user.role) {
    toast.warn("You must be logged in to access this page!", {
      position: "top-right",
      autoClose: 3000,
      style: { marginTop: "70px" },
    });
    return <Navigate to="/lms/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
