import React from "react";
import Swal from "sweetalert2"; // Ensure this is installed via npm install sweetalert2
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ContactPage.css";
import { API_URL } from "../../global";


const ContactPage = () => {
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Username must be at least 3 characters")
        .max(50, "Username must be 50 characters or less")
        .required("Username is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      subject: Yup.string()
        .min(5, "Subject must be at least 5 characters")
        .required("Subject is required"),
      message: Yup.string()
        .min(10, "Message must be at least 10 characters")
        .required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch(`${API_URL}/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Thank you for contacting us. We'll get back to you shortly.",
            confirmButtonText: "OK",
          });
          resetForm();
        } else {
          const errorData = await response.json();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorData.error || "Something went wrong. Please try again.",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Network Error",
          text: "Unable to connect to the server. Please try again later.",
          confirmButtonText: "OK",
        });
      }
    },
  });

  return (
    <div style={{ marginTop: "70px" }}>
      {/* Header Image */}
      <img className="ContactFirst-image" src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1732884483/whfyv7etmogwfir4krmt.png" alt="Contact" />

      {/* Contact Form Section */}
      <div className="contactpage-container">
        <div className="row mt-3">
          {/* Form Section */}
          <div className="col-lg-6 col-md-8 col-sm-12 mx-auto mt-4">
            <h3 className="animate-title text-center">Contact Form</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className={`form-control ${
                    formik.touched.username && formik.errors.username ? "is-invalid" : ""
                  }`}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className="invalid-feedback">{formik.errors.username}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`form-control ${
                    formik.touched.email && formik.errors.email ? "is-invalid" : ""
                  }`}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className={`form-control ${
                    formik.touched.subject && formik.errors.subject ? "is-invalid" : ""
                  }`}
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <div className="invalid-feedback">{formik.errors.subject}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className={`form-control ${
                    formik.touched.message && formik.errors.message ? "is-invalid" : ""
                  }`}
                  rows="4"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></textarea>
                {formik.touched.message && formik.errors.message && (
                  <div className="invalid-feedback">{formik.errors.message}</div>
                )}
              </div>
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
            </form>
          </div>

          {/* Address Section */}
          <div className="col-lg-6 second-column">
            <h2 className="addressinfo">Address Information</h2>
            <div className="addressPage">
              <div className="addresscontent">
                <p>Polyhose Tower No.86, West Wing, 4th Floor</p>
                <p>Anna Salai, Guindy, Chennai, Tamil Nadu-600032</p>
                <br />
                <p className="contact-paratag">
                  Website:{" "}
                  <a
                    href="https://shop.zuppa.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Shop.zuppa.io
                  </a>
                </p>
                <p className="contact-paratag">
                  Email:{" "}
                  <a
                    href="mailto:askme@zuppa.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    askme@zuppa.io
                  </a>
                </p>
                <p className="contact-paratag">
                  Ph. No:{" "}
                  <a href="tel:9952081655" target="_blank" rel="noreferrer">
                    +91 9952081655
                  </a>
                </p>
                <p className="contact-paratag">
                  LinkedIn:{" "}
                  <a
                    href="https://www.linkedin.com/company/zuppa-drones/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zuppa LinkedIn
                  </a>
                </p>
                <p className="contact-paratag">
                  Twitter:{" "}
                  <a
                    href="https://x.com/ZuppaGeoNavTech"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zuppa Twitter
                  </a>
                </p>
                <p className="contact-paratag">
                  Facebook:{" "}
                  <a
                    href="https://www.facebook.com/share/FdfjhFAxGpWufN1p/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zuppa Facebook
                  </a>
                </p>
                <p className="contact-paratag">
                  Instagram:{" "}
                  <a
                    href="https://www.instagram.com/zuppageonav/profilecard/?igsh=MWQybWdteHk1azV5eA=="
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zuppa Instagram
                  </a>
                </p>
                <p className="contact-paratag">
                  Location:{" "}
                  <a
                    href="https://www.google.com/maps/place/Zuppa+Geo+Navigation+Technologies+Private+Limited/@13.0118583,80.2156106,17z/data=!3m1!4b1!4m6!3m5!1s0x3a52678d4cde040b:0xd60e2a228e4ee474!8m2!3d13.0118531!4d80.2181855!16s%2Fg%2F11ft4d2svk?entry=tts"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zuppa Location
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
