import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; 
import axios from 'axios'; 
import './Login.css';
import '@fortawesome/fontawesome-free/css/all.css';
import {useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { UserContext } from '../context/UserContext'; // Adjust the path as needed
import { API_URL } from '../global';
import Swal from 'sweetalert2';



const SignInSignUpForm = () => {

  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const navigate = useNavigate();
  const { saveUser } = useContext(UserContext);

  const toggleMode = () => {
    setIsSignUpMode(!isSignUpMode);
  };


  const signInFormik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      // Show loading popup
      Swal.fire({
        title: 'Logging in...',
        text: 'Please wait',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  
      try {
        const response = await axios.post(`${API_URL}/login`, values);
  
        if (response.data.message === 'Successfully Logged In') {
          // Save user data
          saveUser({
            role: response.data.role,
            user_id: response.data._id,
            batch_id: response.data.batch_id,
            auth:response.data.auth
          });
  
          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Login Successful',
            text: response.data.message,
            timer: 2000, // Auto-close after 2 seconds
            showConfirmButton: false, // Removes the OK button
          }).then(() => {
            // Navigate after success
            if (response.data.role === 'student') {
              navigate(`/lms/homepage`);
            } else {
              navigate(`/lms/homepage`);
            }
          });
        } else {
          // Show error message
          Swal.fire({
            icon: 'error',
            title: 'Login Failed',
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'Something went wrong!',
        });
      }
    },
  });


  const signUpFormik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Username is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),

  onSubmit: async (values) => {
      try {
        const response = await axios.post(`${API_URL}/register`, values);
        if (response.data.message === "Registered successfully. Check your email for further details.") {
          toast.success(response.data.message); // Success toast
          setIsSignUpMode(false);
        } else {
          toast.error(response.data.message); // Error toast
        }
      } catch (error) {
        toast.error(error.response.data.message); // Error toast
      }
    },
  });

const forgotpassword=()=>{
  navigate("/lms/forgot-password")
}

  //*  behavior: "smooth", */
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className={`auth-container ${isSignUpMode ? 'sign-up-mode' : ''}`} style={{marginTop:"75px"}}>
     
     <ToastContainer />
     
     
      <video autoPlay muted loop className="background-video">
        <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1728460436/zfdpomhohfc56rviexrt.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="forms-auth-container">
        <div className="signin-signup">
        
          <form className="sign-in-form" onSubmit={signInFormik.handleSubmit}>
            <h3 className="auth-heading-title">Login</h3>
         
            <div className="auth-input-field">
              <i className="fas fa-envelope" style={{ color: 'black' }}></i>
              <input
                type="email"
                placeholder="Email"
                {...signInFormik.getFieldProps('email')}
                className={`form-control ${signInFormik.touched.email && signInFormik.errors.email ? 'is-invalid' : ''}`}
              />
              {signInFormik.touched.email && signInFormik.errors.email ? (
                <div className="text-danger">{signInFormik.errors.email}</div>
              ) : null}
            </div>
            <div className="auth-input-field">
              <i className="fas fa-lock" style={{ color: 'black' }}></i>
              <input
                type="password"
                placeholder="Password"
                {...signInFormik.getFieldProps('password')}
                className={`form-control ${signInFormik.touched.password && signInFormik.errors.password ? 'is-invalid' : ''}`}
              />
              {signInFormik.touched.password && signInFormik.errors.password ? (
                <div className="text-danger">{signInFormik.errors.password}</div>
              ) : null}
            </div>
            <text  style={{color:'white', textDecoration:'underline', marginLeft:'-220px'}}onClick={forgotpassword}>ForgotPassword ?</text>
            <input type="submit" value="Login" className="auth-btn" />
          </form>
 
       
          <form className="sign-up-form" onSubmit={signUpFormik.handleSubmit}>
            <h3 className="auth-heading-title">Sign up</h3>
            <div className="auth-input-field">
              <i className="fas fa-user" style={{ color: 'black' }}></i>
              <input
                type="text"
                placeholder="Username"
                {...signUpFormik.getFieldProps('username')}
                className={`form-control ${signUpFormik.touched.username && signUpFormik.errors.username ? 'is-invalid' : ''}`}
              />
              {signUpFormik.touched.username && signUpFormik.errors.username ? (
                <div className="text-danger">{signUpFormik.errors.username}</div>
              ) : null}
            </div>
            <div className="auth-input-field">
              <i className="fas fa-envelope" style={{ color: 'black' }}></i>
              <input
                type="email"
                placeholder="Email"
                {...signUpFormik.getFieldProps('email')}
                className={`form-control ${signUpFormik.touched.email && signUpFormik.errors.email ? 'is-invalid' : ''}`}
              />
              {signUpFormik.touched.email && signUpFormik.errors.email ? (
                <div className="text-danger">{signUpFormik.errors.email}</div>
              ) : null}
            </div>
            
            <div className="auth-input-field">
              <i className="fas fa-lock" style={{ color: 'black' }}></i>
              <input
                type="password"
                placeholder="Password"
                {...signUpFormik.getFieldProps('password')}
                className={`form-control ${signUpFormik.touched.password && signUpFormik.errors.password ? 'is-invalid' : ''}`}
              />
              {signUpFormik.touched.password && signUpFormik.errors.password ? (
                <div className="text-danger">{signUpFormik.errors.password}</div>
              ) : null}
            </div>
            <input type="submit" className="auth-btn" value="Sign up" />
          </form>
        </div>
      </div>


      <div className="auth-panels-container">
   
        <div className="auth-panel auth-left-panel">
          <div className="auth-content">

            <iframe style={{ width: '300px', height: '300px' }} src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1732689747/uuuxg4pxwk83jgq4np4t.png" className='auth-image'></iframe>
           
            {/* <button className="auth-btn transparent" style={{ backgroundColor: 'white', color: 'black' }} onClick={toggleMode}>
              Sign up
            </button> */}
             <h2 className="login-subheading" style={{marginLeft:'-100px', marginTop:"-50px"}}>Drone labs</h2>
          </div>
        </div>
   
        <div className="auth-panel auth-right-panel">
          <div className="auth-content">
            <iframe style={{ width: '300px', height: '300px' }} src="https://lottie.host/embed/9ff09fc6-f2b3-40cf-9509-c292c1f876a5/Px1ewPdWFu.json" className='auth-image' title="Sign In"></iframe>
            <button className="auth-btn transparent" style={{ backgroundColor: 'white', color: 'black' }} onClick={toggleMode}>
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInSignUpForm;
