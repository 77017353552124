// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.card:hover{

    box-shadow: 0 8px 20px rgba(243, 108, 6, 0.2);
  }
`, "",{"version":3,"sources":["webpack://./src/Components/Dashboard/CourseManagement.css"],"names":[],"mappings":";AACA;;IAEI,6CAA6C;EAC/C","sourcesContent":["\r\n.card:hover{\r\n\r\n    box-shadow: 0 8px 20px rgba(243, 108, 6, 0.2);\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
