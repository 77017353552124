import React, { useState, useEffect } from 'react';
import { Table, Form, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './CourseManagement.css';
import { API_URL } from '../../global';

const CourseManagement = () => {
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);
  
  const { id } = useParams();

  useEffect(() => {
    const fetchCourses = async () => {
      const response = await axios.get(`${API_URL}/innovation`);
      setCourses(response.data);
    };

    const fetchStudents = async () => {
      const response = await axios.get(`${API_URL}/student/${id}`);
      if (response.status === 200) {
        const fetchedStudents = response.data.getStudents;
        setStudents(Array.isArray(fetchedStudents) ? fetchedStudents : []);
      }
    };

    fetchCourses();
    fetchStudents();
  }, [id]);

  const handleEnableCourse = async (courseId) => {
    const batchId = students[0]?.batch_id;
    const isAssigned = isCourseAssigned(courseId);

    if (isAssigned) {
      await axios.post(`${API_URL}/disable-course`, { courseId, batchId });
    } else {
      await axios.post(`${API_URL}/enable-course`, { courseId, batchId });
    }

    fetchStudents(); // Refresh students to reflect changes
  };

  const fetchStudents = async () => {
    const response = await axios.get(`${API_URL}/student/${id}`);
    if (response.status === 200) {
      const fetchedStudents = response.data.getStudents;
      setStudents(Array.isArray(fetchedStudents) ? fetchedStudents : []);
    }
  };

  // Check if a course is assigned to any student in the batch
  const isCourseAssigned = (courseId) => {
    return students.some(student => 
      student.courses && student.courses.some(course => course.courseId === courseId)
    );
  };

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{marginTop:"50px"}}>
      <Row className="justify-content-center my-4">
        <Col xs="auto">
          <h3 className="text-center" style={{ color: 'orange' }}>Manage Courses</h3>
          <div className="d-flex flex-wrap justify-content-center" style={{ marginTop: "40px" }}>
            <Card className="p-3" style={{ width: '500px', backgroundColor:'#e5e0e0' }}>
              <Card.Body>
                <Card.Title className="text-center" style={{ color: 'black' }}>Available Courses</Card.Title>
                <div className="d-flex flex-row justify-content-center flex-wrap">
                  {courses.map((course) => (
                    <div key={course._id} className="course-toggle mx-2" style={{ color: 'black' }}>
                      <Form.Check
                        type="switch"
                        id={`course-${course._id}`}
                        label={course.NAME}
                        checked={isCourseAssigned(course._id)}
                        onChange={() => handleEnableCourse(course._id)}
                      />
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>

      <Row style={{ paddingTop: "60px" }} className="justify-content-center my-4">
        <Col xs={12} md={10} lg={8}>
          <h5 className="text-center">Student List with Course Status</h5>
          <Table striped bordered hover responsive className="text-center" style={{ minHeight: '350px', marginTop: "40px" }}>
            <thead>
              <tr>
                <th>Student Name</th>
                <th>Batch</th>
                {courses.map(course => <th key={course._id}>{course.NAME}</th>)}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(students) && students.length > 0 ? (
                students.map((student) => (
                  <tr key={student._id}>
                    <td>{student.name}</td>
                    <td>{student.batch_id}</td>
                    {courses.map((course) => (
                      <td key={course._id} style={{ color: student.courses && student.courses.some(c => c.courseId === course._id) ? 'green' : 'red' }}>
                        <b>{student.courses && student.courses.some(c => c.courseId === course._id) ? 'Assigned' : 'Not Assigned'}</b>
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={courses.length + 2}>No students found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default CourseManagement;
