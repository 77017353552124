import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Modal, Button, Container, Form, Accordion } from "react-bootstrap";
import "./Video.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../context/UserContext";
import { API_URL } from "../../global";
import CryptoJS from "crypto-js";

export default function Video() {

  const { user } = useContext(UserContext);

  const [setSections] = useState([]); // Stores sections data from MongoDB
  const [selectedAnswers, setSelectedAnswers] = useState({}); // Stores selected answers
  const [showModal, setShowModal] = useState(false); // State for showing modal
  const [results, setResults] = useState({
    correct: 0,
    wrong: 0,
    unanswered: 0,
  }); // Quiz result state
  const [chapterData, setChapterData] = useState(null); // Chapter data from localStorage
  const [headers] = useState([]); // Dynamically stores headers for sections
  const videoRef = useRef(null); // Ref to access the video element
  const [watchedTime, setWatchedTime] = useState(0); // State to store watched time
  const [duration, setDuration] = useState(0); // State to store video duration
  const [courseData, setCourseData] = useState();
  const [track, setTrack] = useState();
  const [error, setError] = useState(null); // For handling errors
  const [loading, setLoading] = useState(true); // New loading state

  const [lastReportedProgress, setLastReportedProgress] = useState(0); // Track last reported progress
  const [lastVideoWatched, setLastVideoWatched] = useState(0); // Tracks initial video progress
  const [isPlaying, setIsPlaying] = useState(false); // Tracks play/pause state
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const studentId = user.user_id; // Get student ID

  const token = "softw@re-zupp@-te@m-2@19";
  const secretKey = CryptoJS.enc.Utf8.parse("my-secret-key-32bytes1234567890!");

  const encryptedToken = CryptoJS.AES.encrypt(token, secretKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();

  const fetchInitialProgress = async () => {
    // Check if all required parameters are present
    if (
      !studentId ||
      !track?.courseId ||
      !courseData?.sectionName ||
      !courseData?.moduleName ||
      !courseData?.chapterName
    ) {
     
      console.error("Missing required parameters for fetchInitialProgress");
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/video-progress`, {
        params: {
          studentId,
          courseId: track?.courseId,
          sectionName: courseData.sectionName,
          moduleName: courseData.moduleName,
          chapterName: courseData.chapterName,
        },
      });
      setLastVideoWatched(response.data.videoWatched || 0);
      console.log("Initial videoWatched:", response.data.videoWatched);
    } catch (error) {
      console.error("Error fetching initial progress:", error);
    } finally {
      setLoading(false); // Set loading to false after fetch completes
    }
  };

  useEffect(() => {
    const storedChapter = localStorage.getItem("selectedChapter");
    if (storedChapter) {
      try {
        const parsedChapter = JSON.parse(storedChapter);
        setChapterData(parsedChapter.chapter); // Assuming chapter data is nested
        setCourseData(parsedChapter)
        setTrack(parsedChapter)
        setLoading(false);
        fetchInitialProgress();
      } catch (err) {
        console.error("Error parsing chapter data:", err);
        setError("Invalid chapter data in localStorage.");
        setLoading(false);
      }
    } else {
      console.error("No chapter data found in localStorage.");
      setError("No chapter data found.");
      setLoading(false);
    }


      fetchInitialProgress();
 
  }, [track, courseData]);

  const showAlert = () => {
    toast.warn("Inspecting is disabled!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
 
  
  // Function to handle restricted key combinations
  const handleKeyDown = (e) => {
    if (
      (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I
      (e.ctrlKey && e.shiftKey && e.key === "C") || // Ctrl+Shift+C
      (e.shiftKey && e.key === "S" && (e.ctrlKey || e.metaKey)) || // Windows + Shift + S (or Ctrl + Shift + S)
      (e.ctrlKey && e.key === "U") || // Ctrl+U
      e.key === "F12" // F12
    ) {
      e.preventDefault(); // Prevent the default action
      showAlert(); // Show your toast alert
    }
  };

  // Prevent right-click and trigger alert toast
  const handleContextMenu = (e) => {
    e.preventDefault();
    showAlert();
  };

  useEffect(() => {
    // Disable right-click globally
    document.addEventListener("contextmenu", handleContextMenu);

    // Disable specific key combinations
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleContextMenu, handleKeyDown]);


  const handleCopy = (e) => {
    e.preventDefault(); // Prevent the default copy action
    toast.error("Copying is disabled!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); // Show red Toastify alert
  };

  // Fetch data from the backend on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/innovation`);
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };
    fetchData();
  }, []);

  const reportProgress = async (percentage) => {
    try {
      await axios.put(`${API_URL}/update-video-progress`, {
        studentId,
        courseId: track?.courseId,
        sectionName: courseData?.sectionName,
        moduleName: courseData?.moduleName,
        chapterName: courseData?.chapterName,
        videoWatched: percentage,
      });

      console.log(`Progress updated to ${percentage}%`);
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  };

  const handleTimeUpdate = useCallback(() => {
    const video = videoRef.current;
    if (video && isPlaying) {
      const currentWatchedPercentage =
        (video.currentTime / video.duration) * 100;

      // Only update if the current watched percentage is greater than last watched
      if (
        currentWatchedPercentage > lastVideoWatched &&
        currentWatchedPercentage - lastReportedProgress >= 1
      ) {
        reportProgress(Math.floor(currentWatchedPercentage));
        setLastReportedProgress(Math.floor(currentWatchedPercentage));
      }

      setWatchedTime(video.currentTime);
      setDuration(video.duration);
    }
  }, [isPlaying, lastVideoWatched, lastReportedProgress, reportProgress, track]);

  const handlePlay = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      const currentWatchedPercentage =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      if (currentWatchedPercentage > lastVideoWatched) {
        reportProgress(Math.floor(currentWatchedPercentage));
        setLastReportedProgress(Math.floor(currentWatchedPercentage));
      }
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    if (videoRef.current) {
      const currentWatchedPercentage =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      if (currentWatchedPercentage > lastVideoWatched) {
        reportProgress(Math.floor(currentWatchedPercentage));
      }
    }
  };

  useEffect(() => {
    if (loading) return; // Only proceed if loading is false

    const videoElement = videoRef.current;
    if (!videoElement) return;
    videoElement.setAttribute('controlsList', 'nodownload');
    const savedPausedTime = localStorage.getItem("pausedTime");
    if (savedPausedTime && parseFloat(savedPausedTime) > 0) {
      videoElement.currentTime = parseFloat(savedPausedTime);
      localStorage.removeItem("pausedTime");
    }

    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePause);
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [handlePlay, handlePause, handleTimeUpdate, loading]);

  const handleClose = () => setShowModal(false);
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );

    window.scrollTo(0, 0);
  }, [headers]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let correct = 0;
    let wrong = 0;
    let unanswered = 0;
    const unansweredList = []; // Array to track unanswered questions

    if (!chapterData || !Array.isArray(chapterData.QUESTIONS)) {
      console.error("No questions available or invalid chapter data.");
      return;
    }

    chapterData.QUESTIONS.forEach((q, questionIndex) => {
      const selectedOption = selectedAnswers[questionIndex];
      const correctOption = q.OPTIONS[q.ANSWER_IDX];

      if (!selectedOption) {
        unanswered++;
        unansweredList.push(questionIndex); // Add index to unanswered list
      } else if (selectedOption === correctOption) {
        correct++;
      } else {
        wrong++;
      }
    });

    setUnansweredQuestions(unansweredList); // Update state with unanswered questions

    // Only show modal if all questions are answered
    if (unansweredList.length === 0) {
      setResults({ correct, wrong, unanswered });
      setShowModal(true);
    }
  };

  // Handle option change to update selected answers and remove error if answered
  const handleOptionChange = (event, questionIndex) => {
    const updatedSelectedAnswers = {
      ...selectedAnswers,
      [questionIndex]: event.target.value,
    };
    setSelectedAnswers(updatedSelectedAnswers);

    // Remove question index from unansweredQuestions if it's answered
    setUnansweredQuestions((prev) =>
      prev.filter((index) => index !== questionIndex)
    );
  };

  useEffect(() => {
    console.log("Modal state after setting:", showModal);
  }, [showModal]);

  const handleDownload = (documentUrl) => {
    if (documentUrl) {
      const link = document.createElement("a");
      link.href = documentUrl; // Use the dynamically fetched PDF URL here
      link.download = "chapter_document.pdf"; // Adjust the filename as needed
      link.click();
    } else {
      toast.error("PDF document not available for this chapter", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  if (loading) {
    return <p>Loading video...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <>
      <ToastContainer />

      <div class="video-heading-container">
  <h4 className="video-heading-name ">{chapterData.NAME}</h4>
</div>
      <Container>
        {/* Video Section */}
        {chapterData?.VIDEO_URL ? (
          <>
            <div className="video-container" style={{ marginTop: "-30px" }}>
              <video
                ref={videoRef}
                controls
                width="100%"
                height="500px"
                onTimeUpdate={handleTimeUpdate}
              >
                <source
                  src={`${chapterData.VIDEO_URL}?token=${encodeURIComponent(
            encryptedToken
          )}`}
                  type="video/mp4"
                />
                <source
                  src={`${chapterData.VIDEO_URL.replace(".mp4", ".webm")}?token=${encodeURIComponent(
            encryptedToken
          )}`}
                  type="video/webm"
                />
                <source
                  src={`${chapterData.VIDEO_URL.replace(".mp4", ".ogg")}?token=${encodeURIComponent(
            encryptedToken
          )}`}
                  type="video/ogg"
                />
                Your browser does not support the video tag.
              </video>

              <div className="video-flex-container">
                <div className="watched-time mt-2">
                  <p>
                    {/* {chapterData.VIDEO_URL} */}
                    Watched Time: {Math.floor(watchedTime / 60)}:
                    {("0" + Math.floor(watchedTime % 60)).slice(-2)} / Duration:
                    {Math.floor(duration / 60)}:
                    {("0" + Math.floor(duration % 60)).slice(-2)}
                  </p>
                </div>

                <div
                  className="downloadPage"
                  onClick={() => handleDownload(chapterData.DOCUMENT)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    className="watched-timeButton"
                    src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1729837292/rzagj1hajvrikvgaaz0a.gif"
                    alt=""
                  />
                  <p className="watched-timeTag">Download Pdf</p>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* Questions Section */}
    
      </Container>
      <div className="mt-2 quiz-container">
          <div className="d-flex justify-content-center align-items-center vh-60">
            <h2
              className="mt-5"
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "cursive",
              }}
            >
              Questions
            </h2>
          </div>
          <br />
          <br />

          <div onCopy={handleCopy}>
            <div onCopy={(e) => e.preventDefault()}>
              <Form onSubmit={handleSubmit}>
                <div className="accordion-container">
                  <Accordion alwaysOpen>
                    {chapterData &&
                    Array.isArray(chapterData.QUESTIONS) &&
                    chapterData.QUESTIONS.length > 0 ? (
                      chapterData.QUESTIONS.map((q, questionIndex) => (
                        <Accordion.Item
                          eventKey={questionIndex.toString()}
                          key={questionIndex}
                        >
                          <Accordion.Header
                            className="transparent-bg"
                            style={{
                              border: unansweredQuestions.includes(
                                questionIndex
                              )
                                ? "2px solid red"
                                : "",
                            }}
                          >
                            {q.TITLE}
                          </Accordion.Header>
                          <Accordion.Body className="transparent-bg">
                            {q.OPTIONS &&
                              q.OPTIONS.length > 0 &&
                              q.OPTIONS.map((option, optionIndex) => (
                                <Form.Check
                                  type="radio"
                                  id={`question-${questionIndex}-option-${optionIndex}`}
                                  name={`question-${questionIndex}`}
                                  label={option}
                                  value={option}
                                  key={optionIndex}
                                  onChange={(e) =>
                                    handleOptionChange(e, questionIndex)
                                  }
                                  className={`mb-2 option-item ${
                                    selectedAnswers[questionIndex] === option
                                      ? "selected-option"
                                      : ""
                                  }`}
                                />
                              ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))
                    ) : (
                      <p>No questions available for this chapter.</p>
                    )}
                  </Accordion>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "15vh",
                  }}
                >
                  <Button
                    style={{
                      width: "80px",
                      backgroundColor: "orange",
                      border: "1px solid orange",
                      color: "black",
                    }}
                    type="submit"
                    className="mt-6"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          {/* Results Modal */}
          <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: "green", fontWeight: "bolder" }}>
                Correct Answers:{" "}
                <span style={{ color: "black" }}>{results.correct}</span>
              </p>
              <p style={{ color: "red", fontWeight: "bolder" }}>
                Wrong Answers:{" "}
                <span style={{ color: "black" }}>{results.wrong}</span>
              </p>
              <p style={{ color: "blue", fontWeight: "bolder" }}>
                Unanswered:{" "}
                <span style={{ color: "black" }}>{results.unanswered}</span>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      <br />

    </>
  );
}
