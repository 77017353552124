import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import axios from "axios";
import logo from "../../assets/images/headerzuppa.gif";
import { Outlet, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import { UserContext } from "../../context/UserContext";
import { API_URL } from "../../global";
import { MdOutlineLogin } from "react-icons/md";
import "./Portal.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fade } from "react-awesome-reveal";



export default function Portal() {
  const {
    user,
    setUser,
    sections,
    setSections,
    handleSectionClick,
    setSelectedModules,
    setChapters,
    setActiveModuleIndex,
    setActiveIndex,
    isNavbarExpanded,
    setIsNavbarExpanded,
    isCourseDropdownOpen,
    setIsCourseDropdownOpen,
    removeLocal,
    courseHeader,
    setCourseHeader,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [isCourseAccordionOpen, setIsCourseAccordionOpen] = useState(false);
  const courseDropdownRef = useRef(null); // Ref to the dropdown
  // const [getStudentData, setGetStudentData] = useState([]);
  // const [courseCounts, setCourseCounts] = useState({});
  const [studentCourse, setStudentCourse] = useState([]);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown


    // Notify user to login
    const notifyLogin = () => {

      toast.warn("Please log in to access this feature!", {
        position: "top-right",
        autoClose: 3000, // Automatically close after 3 seconds
        hideProgressBar: false, // Show progress bar
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { 
          marginTop: "70px", // Set the top margin for position
          fontSize: "16px"  // Adjust text size
        },
        progressStyle: {
          backgroundColor: "#4caf50" // Set progress bar color
        }
      });
    };
  
    const handleProtectedNavigation = (callback) => {
      if (!user || !user.auth) {
        notifyLogin();
      } else {
        callback();
      }
    };

    const handleCourseClick = () => {
      handleProtectedNavigation(() => {
        if (isNavbarExpanded) {
          setIsCourseAccordionOpen(!isCourseAccordionOpen);
        } else {
          setIsCourseDropdownOpen(!isCourseDropdownOpen);
        }
      });
    };

  // Detect outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        courseDropdownRef.current &&
        !courseDropdownRef.current.contains(event.target)
      ) {
        setIsCourseDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [courseDropdownRef]);

  const fetchStudentGet = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_URL}/student/${user.batch_id}`
      );
      console.log(response.data.getStudents);
      if (response.status === 200) {
        // setGetStudentData(response.data.getStudents);
        calculateCourseCounts(response.data.getStudents);
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  }, [user.batch_id]);

  const calculateCourseCounts = (students) => {
    const counts = {};
    const allCourses = []; // Aggregate all unique courseIds

    students.forEach((student) => {
      student.courses.forEach((course) => {
        const courseId = course.courseId.toString(); // Ensure courseId is a string
        counts[courseId] = (counts[courseId] || 0) + 1; // Increment count
        if (!allCourses.includes(courseId)) {
          allCourses.push(courseId); // Avoid duplicates
        }
      });
    });

    // setCourseCounts(counts);
    setStudentCourse(allCourses); // Store all unique courseIds for this batch
    console.log(allCourses, "Collected courses...");
  };

  useEffect(() => {
    if (user?.batch_id) {
      fetchStudentGet();
    }
  }, [fetchStudentGet]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/innovation`);
        if (response.data && Array.isArray(response.data)) {
          const filteredSections = response.data.filter((section) =>
            studentCourse.includes(section._id)
          );
          setSections(filteredSections);
          setCourseHeader(filteredSections);
          // console.log(object)
          console.log("Filtered Sections: ", filteredSections);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };

    if (studentCourse.length > 0) {
      // Fetch sections only when studentCourse is updated
      fetchData();
    }
  }, [studentCourse]); // Trigger only when studentCourse updates

  useEffect(() => {
    if (user.role && user.role !== "student") {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${API_URL}/innovation`);
          if (response.data && Array.isArray(response.data)) {
            setSections(response.data);
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          console.error("Error fetching sections:", error);
        }
      };

      fetchData();
    }
  }, [user.role]); // Add role as a dependency


  useEffect(() => {
    const savedSection = JSON.parse(localStorage.getItem("selectedSection"));
    const savedActiveIndex = localStorage.getItem("activeIndex");
    const savedModuleIndex = localStorage.getItem("activeModuleIndex");

    if (savedSection && sections.length > 0) {
      handleSectionClick(savedSection);
    }

    if (savedActiveIndex !== null) {
      setActiveIndex(Number(savedActiveIndex));
      const savedModules =
        savedSection.SECTIONS[Number(savedActiveIndex)].MODULES || [];
      setSelectedModules(savedModules);

      // Set chapters to the first module's chapters if they exist
      if (savedModuleIndex !== null && savedModules.length > 0) {
        setActiveModuleIndex(Number(savedModuleIndex));
        setChapters(savedModules[Number(savedModuleIndex)].CHAPTERS || []);
      }
    }
  }, [sections]);


  const handleLogout = () => {
    setIsNavbarExpanded(false); // Collapse the navbar on section click
    localStorage.clear();
    navigate("/lms/login");
    setUser("");
  };

  const profilePage = () => {
    setIsNavbarExpanded(false); // Collapse the navbar on section click
    navigate("/lms/profile");
  };

  return (
<div>
<ToastContainer />
<header className="navbarHeader">
  <Navbar
    expand="lg"
    className="shadow-sm transparent-navbar fixed-navbar"
    expanded={isNavbarExpanded}
    style={{backgroundColor:"rgba(0, 0, 0, 0.5)"}}
  >
    <Container>
      <Navbar.Brand className="logoNameNavbar m-0">
        <img
          src={logo}
          width="100"
          height="50"
          className="d-inline-block align-top"
          alt="React Bootstrap logo"
        />
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="navbar-nav"
        className="menu-icon"
        onClick={() => setIsNavbarExpanded(!isNavbarExpanded)}
      />

      <Navbar.Collapse id="navbar-nav">
        <Nav className="ms-auto">

        <Nav.Link
            className="nav-link logolinkname"
            style={{ marginRight: "30px" }}
            onClick={() => {
            navigate("/lms/homepage");
            if (isNavbarExpanded) {
            setIsNavbarExpanded(!isNavbarExpanded)
           }
          }}
          >
            Home
          </Nav.Link>

          {user && user.auth === "admin" && (<Nav.Link
            className="logolinkname"
            style={{ marginRight: "30px" }}
            onClick={() => {
  navigate("/lms/college");
  if (isNavbarExpanded) {
    setIsNavbarExpanded(!isNavbarExpanded)
  }
}}
    >
     College
    </Nav.Link>)}
  {user.auth !== "admin" && (
<Nav.Link
className="logolinkname"
style={{ marginRight: "30px" }}
onClick={() => handleProtectedNavigation(() => 
  
    user.role === "student"
      ? navigate(`/lms/student-dashboard/${user.batch_id}`)
      : navigate(`/lms/dashboard/${user.user_id}`)
)
}
>
Dashboard
</Nav.Link>
)}

          {/* Mobile Accordion or Normal Dropdown */}
          <div className="course-section" ref={courseDropdownRef}>
            <Nav.Link
              onClick={handleCourseClick}
              className="logolinkname"
            >
              Course
              {isNavbarExpanded && (
                <span className="header-accordion-icon">
                  {isCourseAccordionOpen ? <FaMinus /> : <FaPlus />}
                </span>
              )}
            </Nav.Link>

            {isNavbarExpanded
              ? // Accordion in mobile view when navbar is expanded
                isCourseAccordionOpen && (
                  <div className="accordion-content">
                    {sections && sections.map((section) => (
                      <div
                        className="accordion-item"
                        key={section._id.$oid}
                        onClick={() => {
                          removeLocal();
                          handleSectionClick(section);
                        }}
                      >
                        {section.NAME}
                      </div>
                    ))}
                  </div>
                )
              : // Normal dropdown in desktop view when navbar is collapsed
                isCourseDropdownOpen && (
             <Fade direction="down">
                   <div className={`custom-dropdown-menu ${
                    isCourseDropdownOpen ? "show" : ""
                  }`}>
                    {user.role === "faculty" ? (
                      (
                        sections && sections.map((section) => (
                          <div
                            className="custom-dropdown-item"
                            key={section._id.$oid}
                            onClick={() => {
                              handleSectionClick(section);
                              navigate("/lms/labs");
                              removeLocal();
                            }}
                          >
                            {section.NAME}
                          </div>
                        ))
                      )
                    ) : (
                      user.role === "student" ? (
                        (courseHeader && courseHeader.map((section) => (
                          <div
                            className="custom-dropdown-item"
                            key={section._id.$oid}
                            onClick={() => {
                              handleSectionClick(section);
                              navigate("/lms/labs");
                              removeLocal();
                            }}
                          >
                            {section.NAME}
                          </div>
                        )))
                      ) : (
                       "No Course available"
                      )
                    )}

                   
                  </div>
             </Fade>
                )}
          </div>

          {/* Profile Links for Mobile Only */}
          <div className="logolinkname profile-links d-lg-none  btn-primary">
            {/* <Nav.Link onClick={profilePage}>Profile</Nav.Link> */}
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          </div>

          {/* Conditionally render Dropdown or Login button */}
          {user && user ? (
            <Dropdown className="d-none d-lg-block" align="end">
              <Dropdown.Toggle
                as="img"
                src="https://www.iaccindia.com/wp-content/uploads/2023/01/member-login-icon.png"
                alt="Person Icon"
                style={{
                  width: "44px",
                  height: "44px",
                  marginTop: "-6px",
                  marginLeft: "60px",
                  cursor: "pointer",
                  borderRadius:"50%",
                  backgroundColor:"white"
                }}
              />
              <Dropdown.Menu className="border border-danger">
                {/* <Dropdown.Item onClick={profilePage}>Profile</Dropdown.Item> */}
                <Dropdown.Item onClick={handleLogout} className="text-danger text-center" > <h6 style={{margin:"0px"}}>Logout</h6></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              variant="primary"
              onClick={() => navigate("/lms/login")}
              style={{
                marginLeft: "60px",
               
                height: "44px",
               
              }}
            >
             <h6 style={{width:"100px"}}>Login <MdOutlineLogin  size={30}/></h6> 
            </Button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
</header>
<Outlet />
</div>
  );
}
