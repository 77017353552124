import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Import axios to make API calls
import "./ForgotPassword.css"
import { API_URL } from '../global';




const ForgotPassword = () => {
  const [message, setMessage] = useState(''); // State to handle response messages
  const [error, setError] = useState(''); // State to handle error messages

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      try {
        // Make a POST request to the backend
        const response = await axios.post(`${API_URL}/forgotpassword`, {
          email: values.email,
        });

        // Handle success response
        if (response.status === 200) {
          setMessage(response.data.message);
          setError(''); // Clear any previous errors
        }
      } catch (error) {
        // Handle error response
        if (error.response && error.response.data) {
          setError(error.response.data.message);
        } else {
          setError('An error occurred. Please try again later.');
        }
        setMessage(''); // Clear any previous success message
      }
    },
  });

  return (
    <div className="forgot-password-container">
      {/* Background video */}
      <video autoPlay muted loop className="forgot-background-video">
        <source src="https://res.cloudinary.com/dmv2tjzo7/video/upload/v1728560889/ix5ywxuuk4ii88i3l5su.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div className="row w-100">
          <div className="col-md-6 offset-md-3">
            <div className="card p-4">
              <h3 className="text-center mb-4">Forgot Password</h3>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="email" className="form-label" style={{color:'white'}}>Email Address</label>
                  <input
                    type="email"
                    className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    placeholder="Enter your email"
                    {...formik.getFieldProps('email')}
                  />
                  {/* Error message */}
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
                <button type="submit" className="btn btn-forgot" style={{ width: '150px' }}>
                  <span className="Reset-btn">Send Reset Link</span>
                </button>
              </form>

              {/* Display success or error message */}
              {message && <div className="alert alert-success mt-3">{message}</div>}
              {error && <div className="alert alert-danger mt-3">{error}</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
