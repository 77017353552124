// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color:  rgb(255, 191, 0) rgb(163, 163, 163) ;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(152, 152, 152);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(152, 152, 152);
  border-radius: 20px;
  border: 3px solid orange;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;;AAEpC;;AAEA,qBAAqB;AACrB;EACE,qBAAqB;EACrB,sDAAsD;AACxD;;AAEA,sCAAsC;AACtC;EACE,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE;aACW;AACb","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n \n}\n\n/* Works on Firefox */\n* {\n  scrollbar-width: thin;\n  scrollbar-color:  rgb(255, 191, 0) rgb(163, 163, 163) ;\n}\n\n/* Works on Chrome, Edge, and Safari */\n*::-webkit-scrollbar {\n  width: 12px;\n}\n\n*::-webkit-scrollbar-track {\n  background: rgb(152, 152, 152);\n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: rgb(152, 152, 152);\n  border-radius: 20px;\n  border: 3px solid orange;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
