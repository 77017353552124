import React, { useEffect, useState } from "react";
import { Container, Button, Card  } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { RiAddLargeLine } from "react-icons/ri";
import "./Dashboard.css";
import Footer from "../Footer/Footer";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import 'chart.js/auto';
import ArrImage from "../../assets/StaffDashboard/fast-forward.gif"
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Lottie from 'lottie-react'; // Correct import for lottie-react
import animation from '../Animation/Animation.json'; // Correct path to your JSON file
import NoDataFound from "../Animation/NoDataFound.json"
import Swal from 'sweetalert2';
import { API_URL } from "../../global";



ChartJS.register(ArcElement, Tooltip, Legend, Title);

export default function Dashboard() {

  const navigate = useNavigate();
const [getBatchData, setGetBatchData] = useState([])

const {id} = useParams()

// Utility function to generate random colors
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Prepare Data for Doughnut Chart
const batchLabels = getBatchData.map(batch => batch.batchName);
const batchValues = getBatchData.map(batch => batch.studentCount); // Assuming each batch has a 'studentCount' field
const backgroundColors = getBatchData.map(() => getRandomColor());

const chartData = {
  labels: batchLabels,
  datasets: [
    {
      data: batchValues,
      backgroundColor: backgroundColors,
      hoverBackgroundColor: backgroundColors,
    },
  ],
};

  const fetchBatchGet = async () => {
    try {
      const response = await axios.get(`${API_URL}/college-batch/${id}`);
     
      if (response.status === 200) {
        setGetBatchData(response.data.getBatch);
        console.log(response.data.getBatch,"running......")
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  useEffect(() => {
    fetchBatchGet();
  }, []);

  //-------------------staff dashboard-----------------------

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
    },
  };

  const handleEdit = (batchId) => {
    navigate(`/lms/edit-batch/${batchId}`);
  };

  const handleDelete = async (batchId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you really want to delete this batch? This action cannot be undone.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.delete(`${API_URL}/batch/${batchId}`);
        if (response.status === 200) {
          Swal.fire('Deleted!', 'The batch has been deleted successfully.', 'success');
          fetchBatchGet(); // Refresh the batch list
        }
      } catch (error) {
        console.error("Error deleting batch:", error.response ? error.response.data : error.message);
        Swal.fire('Error!', 'There was an error deleting the batch. Please try again.', 'error');
      }
    }
  };


  return (
    <>
      <br/>
      <br/>
      <Container fluid className="dashboard-container mt-5">
      <div className="d-flex justify-content-end mb-3 mt-3">
        <Button variant="success" onClick={()=> navigate(`/lms/add-batch/${id}`)}> <RiAddLargeLine /> Add-Batch</Button>
      </div>


      <div className="dashboard-content d-flex">
      <div className="dashboard-image">
        <Lottie
          animationData={animation}
          // style={{ height: '400px', width: '300px' }} 
        />
      </div>

    
  <div className="dashboard-chart">
        <Doughnut data={chartData} options={options} />
        </div>
    </div>
    </Container>

     

      <br />
      <hr />
      
      <div className="Staff-dashboard-container">

{
  getBatchData.length > 0 ?  <> 
  {getBatchData.map((data )=> (
    <Card className="dashboard-card" key={data._id} onClick={()=> navigate(`/lms/student-dashboard/${data._id}`)}>
    <div className="card-section first-part">
      <div className="align-batch-details">
      <div className="batchnameHead">BatchName : {data.batchName}</div>
      <div className="card-row"><span className="batchname"> Department :</span>  {data.department}</div>
    </div>
    </div>

    <div className="card-section second-part">
    <div className="align-batch-details">
      <div className="card-row"><span className="batchname">Session : </span>{data.session}</div>
      <div className="card-row"><span className="batchname">Studying Year : </span>{data.studyingYear}</div>
      </div>
    </div>

    <div className="card-section third-part " >
    <div className="align-batch-details">

      <div className="student-count"><span className="batchname">Total Students :</span>  {data.studentCount}</div>
      </div>
      </div>
<div className="card-section icon-section ">
      <img className="arrowImage" src={ArrImage} alt="View Arrow" />

      <EditIcon
      className="editButton"
        fontSize="large"
        variant="contained"
        sx={{ color: "#2e9b99" }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent the card's onClick event
          handleEdit(data._id);
        }}
      />

<DeleteForeverIcon
     className="editButton"
        fontSize="large"
        variant="contained"
        sx={{ color: "#EE4B2B" }}
        onClick={(e) => {
          e.stopPropagation(); // Prevent the card's onClick event
          handleDelete(data._id);
        }}
      />

    </div>
  </Card>
  )) }
  </> : <> <div style={{ 
  display: 'flex', 
  flexDirection:"column",
  justifyContent: 'center', 
  alignItems: 'center', 
  // Adjust height based on your requirement
  width: '100%' 
}}>
  <h2>No Data Found</h2>
  <Lottie
    animationData={NoDataFound}
    style={{ width: '500px' }}
  />
</div></>
}

</div>

<br/>

    </>
  );
}
