import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Modal, Button, Container, Form, Accordion } from "react-bootstrap";
import "./Video.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../context/UserContext";
import { API_URL } from "../../global";
import Footer from "../Footer/Footer";


export default function Video() {

  const { user } = useContext(UserContext);

  const [setSections] = useState([]); // Stores sections data from MongoDB
  const [selectedAnswers, setSelectedAnswers] = useState({}); // Stores selected answers
  const [showModal, setShowModal] = useState(false); // State for showing modal
  const [results, setResults] = useState({
    correct: 0,
    wrong: 0,
    unanswered: 0,
  }); // Quiz result state
  const [chapterData, setChapterData] = useState(null); // Chapter data from localStorage
  const [headers] = useState([]); // Dynamically stores headers for sections
  const videoRef = useRef(null); // Ref to access the video element
  const [watchedTime, setWatchedTime] = useState(0); // State to store watched time
  const [duration, setDuration] = useState(0); // State to store video duration
  const [courseData, setCourseData] = useState();
  const [track, setTrack] = useState();
  const [error, setError] = useState(null); // For handling errors
  const [loading, setLoading] = useState(true); // New loading state

  const [lastReportedProgress, setLastReportedProgress] = useState(0); // Track last reported progress
  const [lastVideoWatched, setLastVideoWatched] = useState(0); // Tracks initial video progress
  const [isPlaying, setIsPlaying] = useState(false); // Tracks play/pause state
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const studentId = user.user_id; // Get student ID
  const [initialFetchDone, setInitialFetchDone] = useState(false); // New flag to prevent multiple fetches
 


  // Fetch initial progress only once on page load
  useEffect(() => {
    const fetchInitialProgress = async () => {


        // Only proceed if the user is a student
    if (user.auth !== "student") {
      // console.log("Request skipped: User is not a student.");
      return;
    }


      if (
        !studentId ||
        !track?.courseId ||
        !courseData?.sectionName ||
        !courseData?.moduleName ||
        !courseData?.chapterName
      ) {
        // console.error("Missing required parameters for fetchInitialProgress");
        return;
      }

      
      try {
        const response = await axios.get(`${API_URL}/video-progress`, {
          params: {
            studentId,
            courseId: track.courseId,
            sectionName: courseData.sectionName,
            moduleName: courseData.moduleName,
            chapterName: courseData.chapterName,
          },
        });
        setLastVideoWatched(response.data.videoWatched || 0);
        // console.log("Initial videoWatched:", response.data.videoWatched);
        setInitialFetchDone(true); // Mark initial fetch as done
      } catch (error) {
        // console.error("Error fetching initial progress:", error);
      }
    };


    const storedChapter = localStorage.getItem("selectedChapter");
    if (storedChapter && !initialFetchDone) {
      try {
        const parsedChapter = JSON.parse(storedChapter);
        setChapterData(parsedChapter.chapter);
        setCourseData(parsedChapter);
        setTrack(parsedChapter);
        fetchInitialProgress();
      } catch (err) {
        // console.error("Error parsing chapter data:", err);
      }
    }
  }, [initialFetchDone, studentId, track, courseData]);
  

  const showAlert = () => {
    toast.warn("Inspecting is disabled!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
 
  
  // Function to handle restricted key combinations
  const handleKeyDown = (e) => {
    if (
      (e.ctrlKey && e.shiftKey && e.key === "I") || // Ctrl+Shift+I
      (e.ctrlKey && e.shiftKey && e.key === "C") || // Ctrl+Shift+C
      (e.shiftKey && e.key === "S" && (e.ctrlKey || e.metaKey)) || // Windows + Shift + S (or Ctrl + Shift + S)
      (e.ctrlKey && e.key === "U") || // Ctrl+U
      e.key === "F12" // F12
    ) {
      e.preventDefault(); // Prevent the default action
      showAlert(); // Show your toast alert
    }
  };

  // Prevent right-click and trigger alert toast
  const handleContextMenu = (e) => {
    e.preventDefault();
    showAlert();
  };

  useEffect(() => {
    // Disable right-click globally
    document.addEventListener("contextmenu", handleContextMenu);

    // Disable specific key combinations
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleContextMenu, handleKeyDown]);


  const handleCopy = (e) => {
    e.preventDefault(); // Prevent the default copy action
    toast.error("Copying is disabled!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }); // Show red Toastify alert
  };

  // Fetch data from the backend on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/innovation`);
        setSections(response.data);
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };
    fetchData();
  }, []);

  const reportProgress = useCallback(async (percentage) => {

           // Only proceed if the user is a student
           if (user.auth !== "student") {
            // console.log("Request skipped: User is not a student.");
            return;
          }

      try {
        if (!isPlaying) return; // Ensure updates occur only when video is playing
    
        await axios.put(`${API_URL}/update-video-progress`, {
          studentId,
          courseId: track?.courseId,
          sectionName: courseData?.sectionName,
          moduleName: courseData?.moduleName,
          chapterName: courseData?.chapterName,
          videoWatched: percentage,
        });
    
        // console.log(`Progress updated to ${percentage}%`);
      } catch (error) {
        console.error("Error updating progress:", error);
      }
    }, [studentId, track, courseData, isPlaying]);
    

// Handle time update
const handleTimeUpdate = useCallback(() => {
  const video = videoRef.current;
  if (video && isPlaying) {
    const currentWatchedPercentage =
      (video.currentTime / video.duration) * 100;

    // Only update if the current watched percentage is greater than last watched
    if (
      currentWatchedPercentage > lastVideoWatched &&
      currentWatchedPercentage - lastReportedProgress >= 1 // Update only if there's a 1% increment
    ) {
      reportProgress(Math.floor(currentWatchedPercentage)); // Update database
      setLastReportedProgress(Math.floor(currentWatchedPercentage));
    }

    setWatchedTime(video.currentTime);
    setDuration(video.duration);
  }
}, [isPlaying, lastVideoWatched, lastReportedProgress, reportProgress]);


const handlePlay = useCallback(() => {
  setIsPlaying(true);
  if (videoRef.current) {
    videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
  }
}, [handleTimeUpdate]);

const handlePause = useCallback(() => {
  setIsPlaying(false);
  if (videoRef.current) {
    videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
  }
}, [handleTimeUpdate]);




  // Video event listeners
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
		videoElement.setAttribute('controlsList', 'nodownload');
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
    }

    return () => {
      if (videoElement) {
		  videoElement.setAttribute('controlsList', 'nodownload');
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      }
    };
  }, [handlePlay, handlePause]);
  const handleClose = () => setShowModal(false);
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );

    window.scrollTo(0, 0);
  }, [headers]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let correct = 0;
    let wrong = 0;
    let unanswered = 0;
    const unansweredList = []; // Array to track unanswered questions
  
    if (!chapterData || !Array.isArray(chapterData.QUESTIONS)) {
      console.error("No questions available or invalid chapter data.");
      return;
    }
  
    chapterData.QUESTIONS.forEach((q, questionIndex) => {
      const selectedOption = selectedAnswers[questionIndex];
      const correctOption = q.OPTIONS[q.ANSWER_IDX];
  
      if (!selectedOption) {
        unanswered++;
        unansweredList.push(questionIndex); // Add index to unanswered list
      } else if (selectedOption === correctOption) {
        correct++;
      } else {
        wrong++;
      }
    });
  
    setUnansweredQuestions(unansweredList);
  
    if (unansweredList.length === 0) {
      const quizScore = correct;
      setResults({ correct, wrong, unanswered });
      setShowModal(true);
  
             // Only proceed if the user is a student
    if (user.auth !== "student") {
      console.log("Request skipped: User is not a student.");
      return;
    }
    
      // Call the backend API to store the results
      try {
        const response = await axios.put(`${API_URL}/submit-quiz`, {
          studentId: user.user_id,
          courseId: courseData.courseId,
          sectionName: courseData.sectionName,
          moduleName: courseData.moduleName,
          chapterName: courseData.chapterName,
          quizScore,
        });
  
        toast.success(response.data.message);
        console.log("Updated Total Current Score:", response.data.totalCurrentScore);
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error("Quiz already submitted for this chapter.");
        } else {
          console.error("Error submitting quiz results:", error);
          toast.error("Failed to submit quiz results.");
        }
      }
  
    }
  };
  

  // Handle option change to update selected answers and remove error if answered
  const handleOptionChange = (event, questionIndex) => {
    const updatedSelectedAnswers = {
      ...selectedAnswers,
      [questionIndex]: event.target.value,
    };
    setSelectedAnswers(updatedSelectedAnswers);

    // Remove question index from unansweredQuestions if it's answered
    setUnansweredQuestions((prev) =>
      prev.filter((index) => index !== questionIndex)
    );
  };

  useEffect(() => {
    // console.log("Modal state after setting:", showModal);
  }, [showModal]);

  const handleDownload = (documentUrl) => {
    if (documentUrl) {
      const link = document.createElement("a");
      link.href = documentUrl; // Use the dynamically fetched PDF URL here
      link.download = "chapter_document.pdf"; // Adjust the filename as needed
      link.click();
    } else {
      toast.error("PDF document not available for this chapter", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  // if (loading) {
  //   return <p>Loading video...</p>;
  // }

  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <>
      <ToastContainer />

      <div class="video-heading-container">
{ chapterData?.NAME ? <h4 className="video-heading-name ">{chapterData.NAME}</h4> : null }
   
</div>
      <Container>
        {/* Video Section */}
        {chapterData?.VIDEO_URL ? (
          <>
            <div className="video-container" style={{ marginTop: "-30px" }}>
              <video
                ref={videoRef}
                controls
                width="100%"
                height="500px"
                onTimeUpdate={handleTimeUpdate}
              >
                <source
                  src={`${chapterData.VIDEO_URL}`}
                  type="video/mp4"
                />
                <source
                  src={`${chapterData.VIDEO_URL.replace(".mp4", ".webm")}`}
                  type="video/webm"
                />
                <source
                  src={`${chapterData.VIDEO_URL.replace(".mp4", ".ogg")}`}
                  type="video/ogg"
                />
                Your browser does not support the video tag.
              </video>

              <div className="video-flex-container">
                <div className="watched-time mt-2">
                  <p>
                    {/* {chapterData.VIDEO_URL} */}
                    Watched Time: {Math.floor(watchedTime / 60)}:
                    {("0" + Math.floor(watchedTime % 60)).slice(-2)} / Duration:
                    {Math.floor(duration / 60)}:
                    {("0" + Math.floor(duration % 60)).slice(-2)}
                  </p>
                </div>

                <div
                  className="downloadPage"
                  onClick={() => handleDownload(chapterData.DOCUMENT)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    className="watched-timeButton"
                    src="https://res.cloudinary.com/dmv2tjzo7/image/upload/v1729837292/rzagj1hajvrikvgaaz0a.gif"
                    alt=""
                  />
                  <p className="watched-timeTag">Download Pdf</p>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* Questions Section */}
    
      </Container>
      <div className="mt-2 quiz-container">
          <div className="d-flex justify-content-center align-items-center vh-60">
            <h2
              className="mt-5"
              style={{
                color: "black",
                fontWeight: "bold",
                fontFamily: "cursive",
              }}
            >
              Questions
            </h2>
          </div>
          <br />
          <br />

          <div onCopy={handleCopy}>
            <div onCopy={(e) => e.preventDefault()}>
              <Form onSubmit={handleSubmit}>
                <div className="accordion-container">
                  <Accordion alwaysOpen>
                    {chapterData &&
                    Array.isArray(chapterData.QUESTIONS) &&
                    chapterData.QUESTIONS.length > 0 ? (
                      chapterData.QUESTIONS.map((q, questionIndex) => (
                        <Accordion.Item
                          eventKey={questionIndex.toString()}
                          key={questionIndex}
                        >
                          <Accordion.Header
                            className="transparent-bg"
                            style={{
                              border: unansweredQuestions.includes(
                                questionIndex
                              )
                                ? "2px solid red"
                                : "",
                            }}
                          >
                            {q.TITLE}
                          </Accordion.Header>
                          <Accordion.Body className="transparent-bg">
                            {q.OPTIONS &&
                              q.OPTIONS.length > 0 &&
                              q.OPTIONS.map((option, optionIndex) => (
                                <Form.Check
                                  type="radio"
                                  id={`question-${questionIndex}-option-${optionIndex}`}
                                  name={`question-${questionIndex}`}
                                  label={option}
                                  value={option}
                                  key={optionIndex}
                                  onChange={(e) =>
                                    handleOptionChange(e, questionIndex)
                                  }
                                  className={`mb-2 option-item ${
                                    selectedAnswers[questionIndex] === option
                                      ? "selected-option"
                                      : ""
                                  }`}
                                />
                              ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))
                    ) : (
                      <p>No questions available for this chapter.</p>
                    )}
                  </Accordion>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "15vh",
                  }}
                >
                  <Button
                    style={{
                      width: "80px",
                      backgroundColor: "orange",
                      border: "1px solid orange",
                      color: "black",
                    }}
                    type="submit"
                    className="mt-6"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          {/* Results Modal */}
          <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: "green", fontWeight: "bolder" }}>
                Correct Answers:{" "}
                <span style={{ color: "black" }}>{results.correct}</span>
              </p>
              <p style={{ color: "red", fontWeight: "bolder" }}>
                Wrong Answers:{" "}
                <span style={{ color: "black" }}>{results.wrong}</span>
              </p>
              <p style={{ color: "blue", fontWeight: "bolder" }}>
                Unanswered:{" "}
                <span style={{ color: "black" }}>{results.unanswered}</span>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      <br />
<Footer/>
    </>
  );
}
