import React, { useEffect } from "react";
// import { Carousel } from "react-bootstrap";
// import axios from "axios";
// import { config } from "react-spring";
import "bootstrap/dist/css/bootstrap.min.css";
// import Dropdown from "react-bootstrap/Dropdown";
// import ProgressBar from 'react-bootstrap/ProgressBar';
import './Profile.css'; // Import your CSS file for styling
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Ensure this import is included
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';




const Profile = () => {
  const navigate = useNavigate();
 // const [sections, setSections] = useState([]); // Stores sections data from MongoDB

 // const [isNavbarExpanded, setIsNavbarExpanded] = useState(false); // New state for controlling navbar collapse
  // const courseDropdownRef = useRef(null); // Ref to the dropdown
  // const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(false); // State to handle dropdown toggle
  // const [slides, setSlides] = useState([]); // Stores images for the carousel
  // const [goToSlide, setGoToSlide] = useState(0); // Controls the current slide
 // const [headers, setHeaders] = useState([]); // Dynamically stores headers for sections
  // const [selectedModules, setSelectedModules] = useState([]); // Holds the modules of the clicked section
  // const [chapters, setChapters] = useState([]);
  // const [activeIndex, setActiveIndex] = useState(0);
  // const [activeModuleIndex, setActiveModuleIndex] = useState(null); // New state for active module index
  // const [activeSection, setActiveSection] = useState(null); // Track the active section
  // const [isCourseAccordionOpen, setIsCourseAccordionOpen] = useState(false);


  // const handleCourseClick = () => {
  //   if (isNavbarExpanded) {
  //     // If in mobile view (navbar expanded), toggle accordion
  //     setIsCourseAccordionOpen(!isCourseAccordionOpen);
  //   } else {
  //     // If in desktop view (navbar collapsed), toggle dropdown
  //     setIsCourseDropdownOpen(!isCourseDropdownOpen);
  //   }
  // };
  
  
  // // Detect outside click
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (courseDropdownRef.current && !courseDropdownRef.current.contains(event.target)) {
  //       setIsCourseDropdownOpen(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [courseDropdownRef]);




  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("http://localhost:4000/innovation");
  //       if (response.data && Array.isArray(response.data)) {
  //         setSections(response.data);
  //       } else {
  //         console.error("Unexpected response format:", response.data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching sections:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);
  

// Function to handle header click and update the carousel
// const handleSectionClick = (section) => {
//   const displayPictures = section["DISPLAY PICTURE"] || [];
//   const newSlides = displayPictures.map((url) => (
//     <Carousel.Item key={url}>
//       <img className="d-block w-100 carousel-img" src={url} alt="carousel-item" />
//     </Carousel.Item>
//   ));
//   setSlides(newSlides);
//   setGoToSlide(0);

//   if (section.SECTIONS && section.SECTIONS[0]) {
//     const sectionHeaders = section.SECTIONS.map((sec) => sec.NAME);
//     setHeaders(sectionHeaders);

//     // Default to the first module and set chapters
//     const firstModule = section.SECTIONS[0].MODULES || [];
//     setSelectedModules(firstModule);
//     setChapters(firstModule[0]?.CHAPTERS || []);
//     setActiveModuleIndex(0); // Automatically select the first module
//   } else {
//     setHeaders([]); // Reset if no sections
//     setSelectedModules([]); // Reset if no modules
//   }

//   setActiveSection(section);
//   setActiveIndex(0);
//   localStorage.setItem("selectedSection", JSON.stringify(section));
//   setIsNavbarExpanded(false);
//   setIsCourseDropdownOpen(false);
//   navigate("/labs")
// };



// const handleLogout = () => {
//   setIsNavbarExpanded(false); // Collapse the navbar on section click
//   navigate("/"); 
// };
// const profilePage =()=>{
// setIsNavbarExpanded(false); // Collapse the navbar on section click
// navigate("/profile"); 
// }
  // Yup validation schema
  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phone: Yup.string().matches(/^\d{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
    roll: Yup.string().required('Roll number is required'),
    batch: Yup.string().required('Please select a batch'),
    year: Yup.number().min(1, 'Year must be at least 1').required('Year is required'),
    department: Yup.string().required('Department is required'),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      phone: '',
      roll: '',
      batch: '',
      year: '',
      department: '',
    },
    validationSchema,
    onSubmit: (values) => {
    
      toast.success('Profile submitted successfully!', {
        position: "top-center",
        autoClose: 3000,
      });


      setTimeout(() => {
        navigate("lms//labs");
      }, 3000);
    },
  });

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );

    window.scrollTo(0, 0);
  }, []);

  return (
    <>

<div className="profile-container container">
      <ToastContainer />
      <div className="row">
        {/* Student Profile Card */}
        <div className="col-lg-6 profile-card">
          <div className="card shadow-lg mb-5">
            <div className="card-header text-center">
              <h4 className='card-headerHeadingTag'>Student Profile</h4>
            </div>
            <form className='form-body' onSubmit={formik.handleSubmit}>
              <div className="card-body">
                    {/* Dynamically show the first letter of the username */}
                    <h1 className='profileCapital'>
                  {formik.values.username ? formik.values.username.charAt(0).toUpperCase() : ''}
                </h1>
                {/* Username */}
                <div className="form-group mb-3">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter username"
                    {...formik.getFieldProps('username')}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="text-danger">{formik.errors.username}</div>
                  ) : null}
                </div>
                {/* Email ID */}
                <div className="form-group mb-3">
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>
                {/* Phone Number */}
                <div className="form-group mb-3">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Enter phone number"
                    {...formik.getFieldProps('phone')}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger">{formik.errors.phone}</div>
                  ) : null}
                </div>
                {/* Roll Number */}
                <div className="form-group mb-3">
                  <label htmlFor="roll">Roll Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="roll"
                    placeholder="Enter roll number"
                    {...formik.getFieldProps('roll')}
                  />
                  {formik.touched.roll && formik.errors.roll ? (
                    <div className="text-danger">{formik.errors.roll}</div>
                  ) : null}
                </div>
                {/* Batch */}
                <div className="form-group mb-3">
                  <label htmlFor="batch">Batch</label>
                  <select
                    className="form-control"
                    id="batch"
                    {...formik.getFieldProps('batch')}
                  >
                    <option value="">Select Batch</option>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                  </select>
                  {formik.touched.batch && formik.errors.batch ? (
                    <div className="text-danger">{formik.errors.batch}</div>
                  ) : null}
                </div>
                {/* Year of Student */}
                <div className="form-group mb-3">
                  <label htmlFor="year">Year</label>
                  <input
                    type="number"
                    className="form-control"
                    id="year"
                    placeholder="Enter year"
                    {...formik.getFieldProps('year')}
                  />
                  {formik.touched.year && formik.errors.year ? (
                    <div className="text-danger">{formik.errors.year}</div>
                  ) : null}
                </div>
                {/* Department */}
                <div className="form-group mb-3">
                  <label htmlFor="department">Department</label>
                  <input
                    type="text"
                    className="form-control"
                    id="department"
                    placeholder="Enter department"
                    {...formik.getFieldProps('department')}
                  />
                  {formik.touched.department && formik.errors.department ? (
                    <div className="text-danger">{formik.errors.department}</div>
                  ) : null}
                </div>
                <div className="text-center">
                  <Button type="submit" variant='contained' color='warning'>Submit</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    </>
   
  );
};

export default Profile;
